// @flow
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Back from '../components/Back';

const Impressum = (props) => (
  <Layout className={props.transitionStatus}>
    <SEO title="Impressum" theme="dark" />
    <h1>Impressum</h1>
		<section className="separator separator--bottom helper--tight">
			<p>
				Die Website wird betrieben von<br />
				<strong>AlbrechtMink GbR</strong><br />
				Seitzstr. 6a<br />
				80538 München
			</p>
		</section>
		<section className="separator separator--bottom helper--tight">
			<p>
				<strong><a href="mailto:albrecht@albrechtmink.com" className="type--email">albrecht@albrechtmink.com</a></strong><br />
				<strong><a href="mailto:mink@albrechtmink.com" className="type--email">mink@albrechtmink.com</a></strong><br />
				<strong><a href="mailto:info@albrechtmink.com" className="type--email">info@albrechtmink.com</a></strong>
			</p>
		</section>
		<section>
			<p>
				Telefon: <a href="tel+49 (0)170 636 52 62">+49 (0)170 636 52 62</a><br />
				Geschäftsführung:  Nikolaus Albrecht & Werner Mink<br />
				UstID–Nr.: DE 280 548 075<br />
				Inhaltlich verantwortlich i.S.v. §5 TMG und § 55 Abs. 2 RStV:<br />
				Nikolaus Albrecht & Werner Mink<br />
				(Adresse siehe oben)<br />
				Die Europäische Kommission stellt unter <a href="https://webgate.ec.europa.eu/odr/" target="_blank" rel="noopener noreferrer" className="type--email">https://webgate.ec.europa.eu/odr/</a> eine Plattform zur Online-Streitbeilegung bereit. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle jedoch weder bereit noch verpflichtet.
			</p>
		</section>
		<Back />
  </Layout>
);

export default Impressum;
